const contacts = [
  {
    name: 'Melody Macy',
    email: 'melody@altbox.com',
    time: '1 week',
    color: 'danger',
  },
  {
    image: 'media/avatars/150-2.jpg',
    name: 'Max Smith',
    email: 'max@kt.com',
    time: '5 hrs',
    color: 'danger',
  },
  {
    image: 'media/avatars/150-4.jpg',
    name: 'Sean Bean',
    email: 'sean@dellito.com',
    time: '20 hrs',
    color: 'danger',
  },
  {
    image: 'media/avatars/150-15.jpg',
    name: 'Brian Cox',
    email: 'brian@exchange.com',
    time: '2 weeks',
    online: true,
    color: 'danger',
  },
  {
    name: 'Mikaela Collins',
    email: 'mikaela@pexcom.com',
    time: '5 hrs',
    online: true,
    color: 'warning',
  },
  {
    image: 'media/avatars/150-8.jpg',
    name: 'Francis Mitcham',
    email: 'f.mitcham@kpmg.com.au',
    time: '20 hrs',
    online: true,
    color: 'danger',
  },
  {
    name: 'Olivia Wild',
    email: 'olivia@corpmail.com',
    time: '3 hrs',
    color: 'danger',
  },
  {
    name: 'Neil Owen',
    email: 'owen.neil@gmail.com',
    time: '3 hrs',
    color: 'primary',
  },
  {
    image: 'media/avatars/150-6.jpg',
    name: 'Dan Wilson',
    email: 'dam@consilting.com',
    time: '3 hrs',
    color: 'danger',
  },
  {
    name: 'Emma Bold',
    email: 'emma@intenso.com',
    time: '1 week',
    online: true,
    color: 'danger',
  },
];

export default contacts;
