
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import Dropdown4 from '@/components/dropdown/Dropdown4.vue';
import contacts from '@/core/data/contacts';
import MessageIn from '@/components/messenger-parts/MessageIn.vue';
import MessageOut from '@/components/messenger-parts/MessageOut.vue';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';

interface KTMessage {
  type: string;
  name?: string;
  image: string;
  time: string;
  text: string;
}

export default defineComponent({
  name: 'private-chat',
  components: {
    MessageIn,
    MessageOut,
    Dropdown4,
  },
  setup() {
    const messagesRef = ref<null | HTMLElement>(null);
    const messagesInRef = ref<null | HTMLElement>(null);
    const messagesOutRef = ref<null | HTMLElement>(null);

    const route = useRoute();

    const messages = ref<Array<KTMessage>>([
      {
        type: 'in',
        name: 'Brian Cox',
        image: 'media/avatars/150-15.jpg',
        time: '5 Hours',
        text: 'How likely are you to recommend our company to your friends and family ?',
      },
      {
        type: 'out',
        image: 'media/avatars/150-2.jpg',
        time: '2 Hours',
        text: 'Hey there, we’re just writing to let you know that you’ve been subscribed to a repository on GitHub.',
      },
      {
        type: 'in',
        name: 'Brian Cox',
        image: 'media/avatars/150-15.jpg',
        time: '2 Hour',
        text: 'Ok, Understood!',
      },
      {
        type: 'out',
        image: 'media/avatars/150-2.jpg',
        time: '2 Hours',
        text: 'You’ll receive notifications for all issues, pull requests!',
      },
      {
        type: 'in',
        name: 'Brian Cox',
        image: 'media/avatars/150-15.jpg',
        time: '1 Hour',
        text: 'You can unwatch this repository immediately by clicking here: Keenthemes.com',
      },
      {
        type: 'out',
        image: 'media/avatars/150-2.jpg',
        time: '4 mins',
        text: 'Most purchased Business courses during this sale!',
      },
      {
        type: 'in',
        name: 'Brian Cox',
        image: 'media/avatars/150-15.jpg',
        time: '2 mins',
        text: 'Company BBQ to celebrate the last quater achievements and goals. Food and drinks provided',
      },
    ]);

    const newMessageText = ref('');

    const addNewMessage = () => {
      if (!newMessageText.value) {
        return;
      }
      messages.value.push({
        type: 'out',
        image: 'media/avatars/150-2.jpg',
        time: 'Just now',
        text: newMessageText.value,
      });

      setTimeout(() => {
        if (messagesRef.value) {
          messagesRef.value.scrollTop = messagesRef.value.scrollHeight;
        }
      }, 1);

      newMessageText.value = '';
      setTimeout(() => {
        messages.value.push({
          type: 'in',
          name: 'Ja Morant',
          image: 'media/avatars/150-15.jpg',
          time: 'Just now',
          text: 'Thank you for your awesome support!',
        });

        setTimeout(() => {
          if (messagesRef.value) {
            messagesRef.value.scrollTop = messagesRef.value.scrollHeight;
          }
        }, 1);
      }, 2000);
    };

    const isGroupChat = computed(() => {
      return route.path.indexOf('/group-chat') !== -1;
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs('Overview', ['Chat'], {});
    });

    return {
      messages,
      messagesRef,
      newMessageText,
      addNewMessage,
      messagesInRef,
      messagesOutRef,
      contacts,
      isGroupChat,
    };
  },
});
